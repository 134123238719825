/* eslint-disable max-len */
import React, {FC} from 'react';
import Layout from '../layouts/Primary';
import SubHeader from '../components/SubHeader';

const FileNotFound: FC = () =>
	<Layout>
		<SubHeader
			title="Help"
		/>
		<div className="ex-basic-2">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="text-container"
							id="trouble-signing-in">
							<h3>Trouble Signing In</h3>
							<h6>When using the Log In page, I cannot sign in.</h6>
							<p>
								This issue typically occurs for users that have not yet signed up for a BeamFire account or have had their account placed on hold.
							</p>
							<p>
								Please confirm that you have an active subscription attached to the desired email address and try again.
							</p>
						</div>

						<a className="btn-outline-reg"
							href="/">BACK</a>
					</div>
				</div>
			</div>
		</div>
	</Layout>;

export default FileNotFound;
